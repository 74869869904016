.container {
    background: #F0F9FE;
    padding: 30px 15px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    /* direction: rtl; */
}

.form {
    width : 100%;
    padding : 0px 220px;
}

.splitTwoInputs {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
}

.dropDownMenus {
    display: grid;
    grid-template-columns: 1fr 1fr;  
    grid-template-rows: auto auto auto; 
    width: 100%;
    gap: 10px;

}

.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}
.button {
    width: 30%;
    height: 20%;
    padding : 25px;
    text-align: center;
    background-color :  #6246C7  !important;
    color : "white";
    font-weight : 700
}

.phoneInput {
    width: 100%;
}

input[name="phone"] {
    padding: 18px 3px;
    margin-bottom: 8px;
}

.captcha{
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* Mobile Version */
@media screen and (max-width : 575px) {
    .form {
        width: 100%;
        padding : 0px 30px;
    }

    .splitTwoInputs {
        flex-direction: column;
    }

    .dropDownMenus {
        display: flex;
        flex-direction: column
    }

}

/* Tablet Version */
@media screen and (max-width : 850px) {
    .form {
        width: 100%;
        padding : 0px 30px;
    }

    .splitTwoInputs {
        flex-direction: column;
    }

    .dropDownMenus {
        display: flex;
        flex-direction: column
    }
}

/* SplitTwoInputs */
@media screen and (max-width : 1000px) {
    .splitTwoInputs {
        flex-direction: column;

    }
}