.container {
   color : white;
   text-align: center;
   padding: 18px 9px;
   font-family: 'Cairo', sans-serif;
}

.headline1 , .headline3{
    font-size: 26px;
    font-weight: 400;
    line-height: 67.46px;
}

.headline2 {
    font-size: 50px;
    font-weight: 700;
    line-height: 93.7px;
}