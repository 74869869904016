
.divchatbot{
    width: 300px;
    margin-bottom: 10px;

}
.inputchatbot{
    width: 100%;
    height: 50px;
    border: none;
    padding-inline-start: 10px;
    border-radius: 10px;
    outline: none;
}
.submitchatbot{
    border: none;
    width: 100%;
    background-color:rgb(110,72,170);
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    margin-top: 20px;
    color: white;
    font-weight: bold;

    
}